//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueNumeric from 'vue-numeric';
import GroupUserIcon from '@/assets/svg/icons/group.svg?inline';
import { mapGetters, mapActions, mapState } from 'vuex';
import { PAGE_SIZE } from '~/mixins/constances';

export default {
  components: {
    VueNumeric,
    GroupUserIcon,
  },
  props: {
    // stores: {
    //   type: Array,
    //   required: true,
    // },
    // loading: {
    //   type: Boolean,
    //   defaultd: false,
    // },
  },
  data() {
    return {
      hasMore: true,
      page: 0,
      now: Date.now(),
    };
  },
  computed: {
    ...mapState({
      isLoading: state => state.v2.dealItemGroup.isLoading,
    }),
    ...mapGetters('v2', ['deliveryInfoCoordicate', 'listDeal', 'soldDeal']),
  },
  async mounted() {
    this.getClearDeal();
    try {
      if (!this.deliveryInfoCoordicate) {
        this.$router.push(this.withPartnerPath('/delivery-address'));
        return;
      }
      await this.getMoreListDeal({
        ...this.deliveryInfoCoordicate,
        size: PAGE_SIZE,
        page: this.page,
      });
    } catch (error) {
      this?.$sentry.captureException(error);
    }
  },
  methods: {
    ...mapActions('v2', ['getDealItemStores', 'getMoreListDeal', 'getClearDeal']),
    async slidesLengthChange(swiper) {
      if (swiper.size < 672 && swiper.activeIndex === PAGE_SIZE * this.page + 3) {
        this.page++;
        await this.getMoreListDeal({
          ...this.deliveryInfoCoordicate,
          size: PAGE_SIZE,
          page: this.page,
        });
      } else if (swiper.activeIndex === PAGE_SIZE * this.page + 1) {
        this.page++;
        await this.getMoreListDeal({
          ...this.deliveryInfoCoordicate,
          size: PAGE_SIZE,
          page: this.page,
        });
      }
    },
  },
};
