//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ClockIcon from '@/assets/svg/icons/clock.svg?inline';
import ClockBlackIcon from '@/assets/svg/icons/clock-black.svg?inline';
import { convertStore, getTimerValue } from '@/plugins/utils';

export default {
  components: { ClockIcon, ClockBlackIcon },
  props: {
    height: String,
    width: String,
    imageUrl: {
      type: String,
    },
    startTime: {
      type: Number,
    },
    endTime: {
      type: Number,
    },
    tag: {
      type: String,
    },
  },
  data() {
    return {
      now: Date.now(),
      dates: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      endDeal: false,
    };
  },
  computed: {
    isLeftOneDate() {
      return this.endTime * 1000 - Date.now() < 24 * 60 * 60;
    },
    timeRemaining() {
      return this.endTime * 1000 - Date.now();
    },
  },
  created() {
    this.countDownTimer();
  },
  methods: {
    countDownTimer() {
      setInterval(() => {
        const { dates, hours, minutes, seconds } = getTimerValue(this.endTime);
        if (this.endTime * 1000 - Date.now() > 0) {
          this.dates = dates;
          this.hours = hours;
          this.minutes = minutes;
          this.seconds = seconds;
        } else {
          this.dates = 0;
          this.hours = 0;
          this.minutes = 0;
          this.seconds = 0;
          this.endDeal = true;
        }
      }, 1000);
    },
  },
};
