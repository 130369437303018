//
//
//
//
//
//
//
//

import { Swiper as SwiperClass, Mousewheel, Autoplay } from 'swiper/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';

SwiperClass.use([Mousewheel, Autoplay]);

const { SwiperSlide } = getAwesomeSwiper(SwiperClass);

export default {
  components: {
    SwiperSlide,
  },
};
