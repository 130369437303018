
export default {
  inheritAttrs: false,
  props: {
    src: {
      type: String,
      required: true,
    },
    srcPlaceholder: {
      type: String,
      default: require('~/assets/images/image-placeholder.webp'),
    },
    srcset: String,
    alt: String,
    height: [Number, String],
    width: [Number, String],
  },
  data: () => ({
    observer: null,
    intersected: false,
    loaded: false,
  }),
  computed: {
    srcImage() {
      return this.intersected && this.src ? this.src : this.srcPlaceholder;
    },
    srcsetImage() {
      return this.intersected && this.srcset ? this.srcset : false;
    },
  },
  mounted() {
    if ('IntersectionObserver' in window) {
      this.observer = new IntersectionObserver(entries => {
        const image = entries[0];

        if (image.isIntersecting) {
          this.intersected = true;
          this.observer.disconnect();
          this.$emit('intersect');
        }
      }, {});

      this.observer.observe(this.$el);
    }
  },
  destroyed() {
    if ('IntersectionObserver' in window) {
      this.observer.disconnect();
    }
  },
  methods: {
    load() {
      if (this.$el.getAttribute('src') !== this.srcPlaceholder) {
        this.loaded = true;
        this.$emit('load');
      }
    },
    error() {
      this.$emit('error', this.$el);
    },
  },
  render(createElement) {
    const img = createElement('img', {
      attrs: {
        src: this.srcImage,
        srcset: this.srcsetImage,
        alt: this.alt,
        height: this.height,
        width: this.width,
      },
      class: {
        'bg-gray-100 border-0': true,
      },
      style: {
        height: this.height,
        width: this.width,
      },
      domProps: this.$attrs,
      on: {
        load: this.load,
        error: this.error,
      },
    });

    return img;
  },
};
